import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import storm from '../assets/images/storm.jpg';
import jonathan from '../assets/images/jonathan.jpg';
import pic01 from '../assets/images/applejacks.jpg';
import pic02 from '../assets/images/psiSemin.jpg';
import pic03 from '../assets/images/covenGrace.jpg';
import pic04 from '../assets/images/warlockSite.jpg';

import config from '../../config';

const IndexPage = () => (
  <Layout>
    <SEO />
    <section id="banner">
      <div className="inner align-right">
        {/*<div className="logo">
          <span className="icon fa-horse"></span>
        </div>*/}
        <h2 className="onWhite">
          Top Hat Unicorn
          <br />
          Productions
        </h2>
        <p className="goAway">Why you highlight?!</p>
        <p className="onWhite">
          <b>{config.subHeading}</b>
        </p>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper alt style4">
        <div className="inner align-center">
          <h2 className="major title bafafuColor">
            The awesomeness we deliver
          </h2>
          <div className="grid-wrapper">
            <div className="col-4 align-center">
              <h1>
                <i className="icon fa-star bafafuColor" />
              </h1>
              <h3 className="bafafuColor">All Inclusive Service</h3>
              <p>
                Skip the weeks of drag-and-drop. We will build your site from
                the ground up. Just give us your content, and our unicorn will
                conjure a magical site for you.
              </p>
            </div>
            <div className="col-4 align-center">
              <h1>
                <i className="icon fa-search bafafuColor" />
              </h1>
              <h3 className="bafafuColor">Responsive Design</h3>
              <p>
                You won't need a magical unicorn monocle to view your website on
                your phone. Our sites are guaranteed to look good on any screen.
              </p>
            </div>
            <div className="col-4 align-center">
              <h1>
                <i className="icon fa-bolt bafafuColor" />
              </h1>
              <h3 className="bafafuColor">Lightning Performance</h3>
              <p>
                People will lose interest if your site is even a few seconds too
                slow. With unicorn magic, your site will load near-instantly for
                anyone who views it.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper spotlight style3">
        <div className="inner">
          <div className="content align-right">
            <h2 className="major bafafuColor">Storm, the Unicorn</h2>
            <p>
              I'm Storm Anduaga-Arias, and I am all about beauty and connection.
              My job is to prettify websites, and create smooth and natural
              content transitions. Through best practices, I help ensure your
              site is a real draw.
            </p>
            {/*<a href="/#" className="special">
              Learn more
            </a>*/}
          </div>
          <div className="image align-right">
            <img src={storm} alt="" className="round" />
          </div>
        </div>
      </section>

      <section id="three" className="wrapper alt spotlight style2">
        <div className="inner">
          <div className="content align-left">
            <h2 className="major bafafuColor">Jonathan, Website Warlock</h2>
            <p>
              This is Jonathan Moss. He's a man of few words. He does most of
              the heavy lifting and problem solving around here. He is usually
              found tinkering with the code and features that make our websites
              so versatile.
            </p>
            {/*<a href="/#" className="special">
              Learn more
          </a>*/}
          </div>
          <div className="align-left image">
            <img src={jonathan} alt="" className="round" />
          </div>
        </div>
      </section>

      <section id="portfolio" className="wrapper last titlePage">
        <div className="inner align-right">
          <h2 className="major bafafuColor">Portfolio</h2>
          <p>
            Check out some example of our work! We build websites for everybody,
            from the little guy up to big companies.
          </p>
          <section className="features">
            <article>
              <a
                href="https://applejacksranch.info"
                className="image"
                target="_blank"
                rel="noopener"
              >
                <img src={pic01} alt="" />
              </a>
              <h3 className="major">Applejack's Ranch</h3>
              <p>
                A non-profit charity, helping to heal the damages of
                sex-trafficking, which is looking to establish a therapy ranch
                in the near future.
              </p>
            </article>

            <article>
              <a
                href="https://project-tophat-unicorn.netlify.app"
                className="image"
                target="_blank"
                rel="noopener"
              >
                <img src={pic02} alt="" />
              </a>
              <h3 className="major">PSI Seminars</h3>
              <p>
                A personal development company that imparts life skills through
                experiential learning. The site is still in development.
              </p>
            </article>

            <article>
              <a
                href="https://covenantofgrace.netlify.app/"
                className="image"
                target="_blank"
                rel="noopener"
              >
                <img src={pic03} alt="" />
              </a>
              <h3 className="major">Covenant of Grace</h3>
              <p>
                A local church, known to the website warlock, which badly needed
                an overhaul to their Internet presence.
              </p>
            </article>

            <article>
              <a
                href="https://jonathanmoss.info"
                className="image"
                target="_blank"
                rel="noopener"
              >
                <img src={pic04} alt="" />
              </a>
              <h3 className="major">Jonathan's Personal Site</h3>
              <p>I mean, the name kinda says it all.</p>
            </article>
          </section>
          {/*<ul className="actions">
            <li>
              <a href="/#" className="button">
                Browse All
              </a>
            </li>
        </ul>*/}
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
